var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"mouseover":function($event){return _vm.isTooltip($event)}}},[(_vm.tooltip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({class:{
          tableItemText: (_vm.linkKey === 'target' && !_vm.isTargetAllowedAsLink) || !_vm.isLinkAllowed || _vm.disableLink,
          tableItemTextLink:
            (_vm.linkKey === 'target' && _vm.isTargetAllowedAsLink) ||
            (_vm.linkKey !== 'target' && _vm.isLinkAllowed && !_vm.disableLink),
        }},on),[(_vm.linkKey === 'target' && _vm.isTargetAllowedAsLink)?_c('div',{staticClass:"tableLinkDisabled",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`}},[_c('span',{staticClass:"tableLink cursor-pointer",on:{"click":_vm.linkClicked}},[_vm._v(" "+_vm._s(_vm.workloadName)+" ")]),_c('span',{staticClass:"tableLinkDisabled"},[_vm._v(" :"+_vm._s(_vm.workloadPort)+" ")])]):_vm._e(),(_vm.linkKey !== 'target' && _vm.isLinkAllowed && !_vm.disableLink)?_c('span',{staticClass:"tableLink cursor-pointer",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`},on:{"click":_vm.linkClicked}},[_vm._v(" "+_vm._s(_vm.tableItem[_vm.linkKey])+" ")]):_vm._e(),(
            (_vm.linkKey !== 'target' && (!_vm.isLinkAllowed || _vm.disableLink)) ||
            (_vm.linkKey === 'target' && !_vm.isTargetAllowedAsLink)
          )?_c('span',{staticClass:"tableLinkDisabled",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`}},[_vm._v(" "+_vm._s(_vm.tableItem[_vm.linkKey])+" ")]):_vm._e()])]}}],null,false,2478211771)},[_c('span',[_vm._v(_vm._s(_vm.tableItem[_vm.linkKey]))])]):_c('div',{staticClass:"pa-0 ma-0",class:{
      tableItemText: (_vm.linkKey === 'target' && !_vm.isTargetAllowedAsLink) || !_vm.isLinkAllowed || _vm.disableLink,
      tableItemTextLink:
        (_vm.linkKey === 'target' && _vm.isTargetAllowedAsLink) || (_vm.linkKey !== 'target' && _vm.isLinkAllowed && !_vm.disableLink),
    }},[(_vm.linkKey === 'target' && _vm.isTargetAllowedAsLink)?_c('div',{staticClass:"tableLinkDisabled",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`}},[_c('span',{staticClass:"tableLink cursor-pointer",on:{"click":_vm.linkClicked}},[_vm._v(" "+_vm._s(_vm.workloadName)+" ")]),_c('span',{staticClass:"tableLinkDisabled"},[_vm._v(" :"+_vm._s(_vm.workloadPort)+" ")])]):_vm._e(),(_vm.linkKey !== 'target' && _vm.isLinkAllowed && !_vm.disableLink)?_c('span',{staticClass:"tableLink cursor-pointer",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`},on:{"click":_vm.linkClicked}},[_vm._v(" "+_vm._s(_vm.tableItem[_vm.linkKey])+" ")]):_vm._e(),(
        (_vm.linkKey !== 'target' && (!_vm.isLinkAllowed || _vm.disableLink)) || (_vm.linkKey === 'target' && !_vm.isTargetAllowedAsLink)
      )?_c('span',{staticClass:"tableLinkDisabled",attrs:{"id":`iiotTableContentAsLink_${_vm.linkKey}_${_vm.index}`}},[_vm._v(" "+_vm._s(_vm.tableItem[_vm.linkKey])+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }